import { StaticQuery, graphql } from "gatsby"
import React from "react"

import Img from "gatsby-image"

const Cients = () => (
  <StaticQuery
    query={graphql`
        query {
            allWordpressAcfClients {
                edges {
                    node {
                        acf {
                            title
                            website
                            logo {
                                localFile {
                                    childImageSharp {
                                        sizes(maxWidth: 600) {
                                            ...GatsbyImageSharpSizes
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `}
    render={data => (
        <section className="section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="text-center mb-5 pt-5">
                            <h3 className="title mb-3">Our Clients</h3>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center">
                {data.allWordpressAcfClients.edges.map(client => (
                    <div className="col-lg-2 mt-3">
                        <div className="clients-box">
                            <a href={client.node.acf.website} target="_blank" rel="noreferrer">
                                <Img
                                sizes={client.node.acf.logo.localFile.childImageSharp.sizes}
                                alt={client.node.acf.title}
                                className="img-responsive"
                                style={{maxWidth: `100%`, maxHeight: `100%`}}
                                />
                            </a>
                        </div>
                    </div>
                ))}
                </div>
            </div>
        </section>
    )}
  />
)

export default Cients