import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout.js"
import SEO from "../components/seo"
import Clients from "../components/clients"

const PageTemplate = ({ data }) => (
  <Layout>
    <SEO
      title={data.wordpressPage.acf.title}
      description={data.wordpressPage.acf.excerpt}
    />
    <div className="container top-heading">
        <div className="row">
            <div className="col-md-6">
                <h1 className="pb-5">{data.wordpressPage.acf.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.about }} />
            </div>
            <div className="col-md-6">
                <Img
                    sizes={data.wordpressPage.acf.about_image.localFile.childImageSharp.sizes}
                    alt={data.wordpressPage.acf.title}
                    className="img-fluid d-block mx-auto" 
                />
            </div>
        </div>
        <Clients />
    </div>
  </Layout>
)

export default PageTemplate

export const query = graphql`
  query{
    wordpressPage(slug: {eq: "about"}) {
        acf {
          title
          about
          about_image {
            localFile {
                childImageSharp {
                    sizes(maxWidth: 1200) {
                        ...GatsbyImageSharpSizes
                    }
                }
            }
          }
        }
    }
  }
`